import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import P from "../components/P";

function AboutPage() {
  return (
    <Layout>
      <SEO title="About Us" />
      <header>
        <h1>About Us</h1>
      </header>
      <P>Welcome to the Burch Rentals website!</P>
      <P>
        This is a family owned and operated business of three generations. We
        pride ourselves on well maintained property and excellent tenant care.
        Most of our maintenance is done in house which enables us to pass on the
        savings to our tenants. We know there are a lot of property choices in
        Springfield, most of which are operated by management companies. Since
        we own these properties, it is in our interest to maintain excellent
        standards. We hope this site will give you the information you need to
        choose your next home.
      </P>
      <br />
      <P>
        <i>-The Burch Family</i>
      </P>
    </Layout>
  );
}

export default AboutPage;
